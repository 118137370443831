class AlertHelper {

  static alertSuccess(window,scope) {
    window.$swal.fire({
      title: `${window.$t("SWEETALERTS." + scope + ".TITLE")}`,
      text: `${window.$t("SWEETALERTS." + scope + ".MESSAGE")}`,
      icon: 'success',
      confirmButtonText: `${window.$t("GENERAL.TEXT.CONFIRM")}`
    })
  }

  static alertFailure(window,scope) {
    window.$swal.fire({
      title: `${window.$t("SWEETALERTS." + scope + ".TITLE")}`,
      text: `${window.$t("SWEETALERTS." + scope + ".MESSAGE")}`,
      icon: 'error',
      confirmButtonText: `${window.$t("GENERAL.TEXT.CONFIRM")}`
    })
  }
}

export default AlertHelper;