<template>
  <b-form-group
    :id="id + 'group'"
    :label="computedLabel"
    :label-for="id"
    :label-sr-only="labelSrOnly"
    class="mb-0"
  >
    <b-form-select
      :id="id"
      v-model="model.$model"
      class="form-control form-control-solid px-6 h-auto"
      :class="{'py-3' : computedSlim, 'py-4' : !computedSlim }"
      :aria-describedby="'feedback-' + id"
      :state="validateState()"
      :disabled="computedDisabled"
    >
      <b-form-select-option
        v-if="showPlaceholder"
        value="null"
      >
        {{ placeholder }}
      </b-form-select-option>
      <template v-for="(option, i) in options">
        <b-form-select-option
          :key="i"
          v-if="option['divider'] != undefined && option['divider'] == true"
          disabled
        >
          {{ option['header'] }}
        </b-form-select-option>
        <b-form-select-option
          :key="i"
          v-else-if="option[optionLabel] != undefined"
          :value="option[computedValue]"
        >
          {{ AddTimeZoneDropDown(i) | stripHTML }}
        </b-form-select-option>
      </template>
    </b-form-select>
    <b-form-invalid-feedback
      :id="'feedback-' + id"
      v-if="displayError"
    >
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <b-form-text
    v-if="helper != undefined"
    class="px-5 py-1"
    :id="'helper-' + id"
    >
      {{ helper }}
    </b-form-text>
  </b-form-group>
</template>

<script>
export default {
  name: "GenericDropdown",

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    options: { type: Array, required: true },
    disabled: { type: Boolean, required: false },
    errorMessage: { type: String, required: false },
    label: { type: String, required: false },
    placeholder: { type: String, required: false },
    slim: { type: Boolean, required: false },
    helper: { type: String, required: false },
    optionLabel: { type: String, required: false },
    value: { type: String, required: false }
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
    AddTimeZoneDropDown(key) {
      if( (this.id == 'input-timezone-code') && (this.label == 'Timezone') ){
        return `${this.options[key]['name']} (${this.options[key]['offset']})`
      } else {
        return this.options[key][this.optionLabel];
      }
    }
  },

  computed: {
    labelSrOnly: function() {
      if (this.label != undefined) {
        return false;
      } else {
        return true;
      }
    },
    displayError: function() {
      if (this.errorMessage != undefined) {
        return true;
      } else {
        return false;
      }
    },
    computedLabel: function() {
      if (this.model.$params.hasOwnProperty('required')) {
        if (this.model.required) {
          return this.label + "*";
        } else {
          return this.label;
        }
      } else {
        return this.label;
      }
    },
    showPlaceholder: function() {
      if (this.placeholder != undefined) {
        return true;
      } else {
        return false;
      }
    },
    computedValue: function() {
      if (this.value != undefined) {
        return this.value;
      } else {
        return 'id';
      }
    },
    computedDisabled: function() {
      if (this.disabled != undefined) {
        return this.disabled;
      } else {
        return false
      }
    },
    computedSlim: function() {
      if (this.slim != undefined) {
        return this.slim;
      } else {
        return false;
      }
    }
  }
};
</script>