<template>
  <div class="p-6">
    <div class="row d-flex justify-content-center w-100 mb-10">
      <div class="w-75">
        <InputGeneric
          id="input-filter-name"
          class="px-10"
          :model="$v.filter"
          alignLabel="right"
          :placeholder="$t('GENERAL.INPUT.SEARCH')"
          :submissionStates="{ submitting: false }"
          :showInline="false"
        >
        </InputGeneric>
      </div>
    </div>
    <div class="row">
      <div :class="(filter.length == 0) ? 'col-3' : 'd-none'" style="border-right: 1px solid #ebf3eb">
        <ul class="navi navi-link-rounded navi-accent navi-hover flex-column mb-8 mb-lg-0" role="tablist">
          <template v-for="(folder, f) in helpArticles">
            <li :key="f" class="navi-item mb-2">
              <a class="navi-link" :class="(folder.id == selectedFolder) ? 'active' : null" data-toggle="tab" href="javascript:void(0)" @click="selectFolder(folder)">
                <span class="navi-text text-dark-75 font-size-normal font-weight-bold">{{ folder['name'] }}</span>
              </a>
            </li>
          </template>
        </ul>
      </div>

      <div :class="(filter.length == 0) ? 'col-9' : 'col-12'">
        <div class="accordion accordion-light accordion-light-borderless accordion-svg-toggle">
          <template v-for="(article, a) in calculatedArticles">
            <div :key="a" class="card mb-3" style="box-shadow: none !important">
              <div class="card-header article-header" @click="selectedArticle = (selectedArticle == article.id) ? null : article.id">
                <div class="card-title">
                  <a class="navi-text text-dark-75 font-size-normal font-weight-bold pl-4" href="javascript:void(0)" style="font-size: 1rem !important">
                    {{ article.title }}
                  </a>
                </div>
              </div>
              <div v-if="selectedArticle == article.id">
                <div class="card-body text-dark-50 font-size-normal pl-12">
                  <!-- <a :href="article.url" target="_blank">{{ article.url }}</a> -->
                  <div class="mt-5">
                    <span v-html="article.body"></span>                        
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";

import InputGeneric from "@/view/components/form-inputs/GenericInput";

export default {
  mixins: [validationMixin],

  name: "HelpArticles",

  data() {
    return {
      filter: "",
      selectedFolder: null,
      selectedArticle: null
    }
  },

  components: {
    InputGeneric
  },

  validations: {
    filter: {}
  },

  methods: {
    articleInSearch: function(article) {
      if (this.filter.length == 0) {
        return true;
      } else {
        let title = article['title'].toLowerCase();
        let filter = this.filter.toLowerCase();

        return (title.includes(filter) ? true : false);
      }
    },
    selectFolder: function(folder) {
      this.selectedArticle = null;
      this.selectedFolder = folder.id;
    }
  },

  computed: {
    ...mapGetters([
      "helpArticles"
    ]),

    /**
     * Algorithm is based on priority:
     * 1. If an article has been selected then only that article will be returned
     * 2. If an article has not been selected and a search has been applied then only articles that comply with that search will be returned
     * 3. If a search has not been applied then only articles nested under the selected folder will be returned
     * 
     * It is possible and common for no articles to be returned.
     * @return array of articles
     */
    calculatedArticles: function() {
      if ((this.selectedFolder == null && this.filter.length == 0)) {
        return [];
      } else {
        let articles = [];
        const self = this;

        for (const [folderKey, folder] of Object.entries(this.helpArticles)) {
          for (const [articleKey, article] of Object.entries(folder.articles)) {
            if (self.selectedArticle != null && self.selectedArticle == article.id) {
              articles.push(article);
            } else if (self.selectedArticle == null) {
              if (self.filter.length > 0 && article.title.toLowerCase().includes(self.filter.toLowerCase())) {
                articles.push(article);
              } else if (self.filter.length == 0 && (self.selectedFolder != null && folderKey == self.selectedFolder)) {
                articles.push(article);
              }
            }
          }
        }

        return articles;
      }
    },
  }
}
</script>

<style>
  .article-header:hover {
    background: #f0f1f2 !important;
  }
</style>