<template>
  <div>
    <button
      v-if="buttonType == 1"
      v-b-modal.help-modal
      class="btn btn-icon btn-hover-transparent-primary d-flex align-items-center btn-lg px-md-2 w-md-auto pb-1 mr-1"
      v-b-tooltip.hover.bottom
      :title="$t('TOOLBAR.HELP.TOOLTIP')"
    >
      <span class="svg-icon svg-icon-1 svg-icon-warning">
        <inline-svg src="media/svg/icons/Home/Bulb1.svg" />
      </span>
    </button>

    <a v-else class="text-white" href="javascript:void(0)" v-b-modal.help-modal>
      {{ $t("TOOLBAR.HELP.TOOLTIP") }}
    </a>

    <b-modal
      :size="tab == 1 ? 'xl' : 'lg'"
      id="help-modal"
      ref="help-modal"
      :title="
        tab == 1 ? $t('HELP.KNOWLEDGE_BASE.TITLE') : $t('HELP.TICKET.TITLE')
      "
      body-class="px-5 py-7"
      :hide-footer="!loadingComplete"
    >
      <b-spinner
        v-if="loadingComplete == false"
        variant="primary"
        label="Spinning"
        class="card-loader"
      >
      </b-spinner>

      <div v-else>
        <Articles v-if="tab == 1"> </Articles>

        <ZendeskForm
          ref="help-form"
          v-if="tab == 2"
          :isAuthenticated="isAuthenticated"
        >
        </ZendeskForm>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <!--Hide Modal-->
          <div class="row d-flex justify-content-start mx-0">
            <button
              @click="closeModal"
              class="btn btn-outline-primary btn-sm mr-3"
            >
              {{ $t("GENERAL.BUTTONS.CANCEL") }}
            </button>
          </div>

          <div class="row d-flex justify-content-start">
            <!--Back Button-->
            <button
              v-if="tab == 2"
              @click="tab = 1"
              class="btn btn-outline-primary btn-sm mx-1"
            >
              {{ $t("GENERAL.BUTTONS.BACK") }}
            </button>

            <!--Show Help Form-->
            <button
              v-if="tab == 1"
              @click="tab = 2"
              class="btn btn-primary btn-sm mx-1"
            >
              {{ $t("GENERAL.BUTTONS.REQUEST") }}
            </button>

            <!--Submit Form-->
            <button
              v-if="tab == 2 && computedTypeOfIssueDisplay"
              @click="onSubmit"
              class="btn btn-primary btn-sm mx-1"
              :disabled="form.submissionStates.submitting"
            >
              {{ $t("GENERAL.BUTTONS.SUBMIT") }}
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { mapMutations } from "vuex";

import Articles from "@/modules/together-helpers/help/Articles";
import ZendeskForm from "@/modules/together-helpers/help/Ticket";

import AlertHelper from "@/modules/together-helpers/helpers/AlertHelper";
import PageLoader from "@/core/classes/PageLoader.js";

import { POPULATE_ARTICLES, CREATE_HELP_TICKET } from "@/modules/together-helpers/vuex/system/zendesk.module";

export default {
  name: "Helpdesk",

  props: {
    buttonType: { type: Number, required: false, default: 1 },
  },

  data() {
    return {
      tab: 1,
      pageLoader: new PageLoader(1),
    };
  },

  watch: {
    $route(to, from) {
      let goingToForum = this._.some(to, (el) => _.includes("forum", el));
      if (goingToForum) {
        this.closeModal();
      }
    },
    immediate: true,
    deep: true,
  },

  components: {
    Articles,
    ZendeskForm,
  },

  mounted() {
    this.fetchSupportArticles();
  },

  methods: {
    fetchSupportArticles: function () {
      this.pageLoader.setCompleteComponent(1);
      this.$store
        .dispatch(POPULATE_ARTICLES, {
          params: { node: process.env.VUE_APP_SUBMODULE_IDENTIFIER },
        })
        .then(() => {
          this.pageLoader.setCompleteComponent(1);
        });
    },
    onSubmit: function () {
      if (!this.$refs["help-form"].canProgress()) {
        return false;
      }

      this.form.submissionStates.submitting = true;

      this.form.email = this.isAuthenticated
        ? this.currentUser.calculatedEmail
        : this.form.email;
      this.form.language = this.isAuthenticated
        ? this.currentUser.language.name
        : "English";

      let payload = {
        data: this.form,
      };

      payload.data.node = process.env.VUE_APP_SUBMODULE_IDENTIFIER;
      payload.data.user_id = this.isAuthenticated ? this.currentUser.id : null;
      payload.data.project_id = this.isProjectDomain
        ? this.projectInfo.id
        : null;

      payload.data.prefix = window.location.host.split(".")[0].includes();

      this.$store
        .dispatch(CREATE_HELP_TICKET, payload)
        .then(() => {
          AlertHelper.alertSuccess(this, "HELP_SENT_SUCCESS");
          this.closeModal();
        })
        .finally(() => {
          this.form.submissionStates.submitting = false;
        });
    },
    closeModal: function () {
      this.tab = 1;
      this.resetForm();
      this.$refs["help-modal"].hide();
    },
    ...mapMutations({
      resetForm: "resetHelpForm",
    }),
  },

  computed: {
    ...mapGetters(["currentUser", "projectInfo", "helpArticles"]),
    ...mapState({
      form: (state) => state.zendesk.help_form,
    }),
    loadingComplete() {
      return this.pageLoader.getStatus();
    },
    computedTypeOfIssueDisplay: function () {
      let chosenOption = this.form.type_of_issue;
      let displayArray = [4, 5, 6];
      if (displayArray.includes(chosenOption)) {
        return false;
      } else {
        return true;
      }
    },
    calculatedArticleOrigin: function () {
      let routeScope = this.$route.meta.scope;
      let returnOrigin = "";

      if (routeScope == "core") {
        returnOrigin = "Admin Help";
      } else if (routeScope == "client") {
        returnOrigin = "Client Help";
      } else if (routeScope == "project") {
        returnOrigin = "Project Help";
      }

      return returnOrigin;
    },
    isAuthenticated: function () {
      return this.currentUser.id != undefined;
    },
    isProjectDomain: function () {
      return this.projectInfo.id != undefined;
    },
  },
};
</script>
