<template>
  <div>
    <ErrorBox :errors="helpErrors" @clear_errors="setHelpErrors({})">
    </ErrorBox>

    <div class="px-10">
      <InputGenericDropdown
        class="mb-7"
        id="input-type-of-issue"
        :options="typeOfIssue"
        optionLabel="title"
        :label="$t('HELP.TICKET_FORM.TYPE_OF_ISSUE_LABEL')"
        :showInline="true"
        :model="$v.form.type_of_issue"
        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
        :submissionStates="form.submissionStates"
        :stateValidation="false"
      >
      </InputGenericDropdown>
    </div>

    <div v-if="computedTypeOfIssueDisplay">
      <!--START::Email-->
      <div v-if="!isAuthenticated" class="px-10">
        <label for="input-email" class="d-block">{{
          $t("FORMS.INPUTS.EMAIL")
        }}</label>
        <InputGeneric
          id="input-email"
          class="mb-7"
          :model="$v.form.email"
          alignLabel="left"
          :placeholder="$t('FORMS.INPUTS.EMAIL')"
          :showInline="false"
          :error="$t('FORMS.ERROR.REQUIRED')"
          type="text"
          :submissionStates="form.submissionStates"
        >
        </InputGeneric>
      </div>
      <!--END::Email-->

      <!--START::Subject-->
      <div class="px-10">
        <label for="input-subject" class="d-block">{{
          $t("FORMS.INPUTS.SUBJECT")
        }}</label>
        <InputGeneric
          id="input-subject"
          class="mb-7"
          :model="$v.form.subject"
          alignLabel="left"
          :placeholder="$t('FORMS.INPUTS.SUBJECT')"
          :showInline="false"
          :error="$t('FORMS.ERROR.REQUIRED')"
          type="text"
          :submissionStates="form.submissionStates"
        >
        </InputGeneric>
      </div>
      <!--END::Subject-->

      <!--START::Description-->
      <div class="px-10">
        <GenericTextArea
          id="input-description"
          :label="$t('FORMS.INPUTS.DESCRIPTION')"
          :model="$v.form.description"
          :submissionStates="form.submissionStates"
          :error="$t('FORMS.ERROR.REQUIRED')"
          rows="8"
        >
        </GenericTextArea>
      </div>
      <!--END::Description-->
    </div>

    <div v-else class="px-10 text-center" v-html="BlogLinkText()"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import { required, requiredIf, email } from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import InputGeneric from "@/modules/together-helpers/components/generic-inputs/GenericTextInput";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";
import GenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";

export default {
  mixins: [validationMixin],

  name: "HelpTicket",

  props: {
    isAuthenticated: { type: Boolean, required: true },
  },

  validations: {
    form: {
      email: {
        email,
        required: requiredIf(function () {
          return !this.isAuthenticated;
        }),
      },
      type_of_issue: { required },
      subject: { required },
      description: { required },
    },
  },

  components: {
    ErrorBox,
    InputGeneric,
    GenericTextArea,
    InputGenericDropdown,
  },

  mounted() {
    this.cleanup();
  },

  methods: {
    BlogLinkText() {
      let text = this.$t("HELP.TICKET_FORM.REDIRECT_TO_ACTIVITIES");
      return `${text} <a href="/#/forum"> here </a>`;
    },
    canProgress: function () {
      this.$v.form.$touch();
      return this.$v.form.$anyError ? false : true;
    },
    cleanup: function () {
      this.form.email = null;
      this.form.subject = null;
      this.form.description = null;
      this.form.type_of_issue = 1;
      this.setHelpErrors({});
    },
    ...mapMutations({
      setHelpErrors: "setHelpErrors",
    }),
  },

  computed: {
    ...mapGetters(["helpErrors"]),
    ...mapState({
      form: (state) => state.zendesk.help_form,
    }),
    computedTypeOfIssueDisplay: function () {
      let chosenOption = this.form.type_of_issue;
      let displayArray = [4, 5, 6];
      if (displayArray.includes(chosenOption)) {
        return false;
      } else {
        return true;
      }
    },
    typeOfIssue: function () {
      let env = process.env.VUE_APP_SUBMODULE_IDENTIFIER.toLowerCase();

      let arr = [
        {
          id: "Login problems",
          title: `${this.$t("HELP.TICKET_FORM.LOGIN_PROBLEM")}`,
        },
        {
          id: "Registration",
          title: `${this.$t("HELP.TICKET_FORM.REGISTRATION")}`,
        },
        {
          id: "Personal information inquiry",
          title: `${this.$t("HELP.TICKET_FORM.PII")}`,
        },
        {
          id: 4,
          title: `${this.$t("HELP.TICKET_FORM.COMMUNITY_ACTIVTIES")}`,
        },
        { id: 5, title: `${this.$t("HELP.TICKET_FORM.INCENTIVES")}` },
        { id: 6, title: `${this.$t("HELP.TICKET_FORM.PROJECT_HELP")}` },
      ];

      if (!this.isAuthenticated || env == "admin") {
        arr = _.filter(arr, function (Obj) {
          let bool = Obj.id == 4 || Obj.id == 5 || Obj.id == 6;
          return !bool;
        });
      }
      return arr;
    },
  },
};
</script>